<template>
  <div class="crowd">
    <!-- subTitle="对应课程 更快上岸" -->
    <use-sub-title title="课程适用学员" :isSubTitle="false" lineWidth="130px"></use-sub-title>
    <ul class="crowd_body">
      <li v-for="(item, index) in dataList" :key="index">
        <img :src="require(`@/assets/home/crowd/${item.img_url}`)" alt="">
        <p class="title">
          {{ item.title }}
        </p>
        <p class="sub_title">
          {{ item.subTitle }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { reactive } from 'vue';


const dataList = reactive([
  {
    title: '自学入门',
    subTitle: '学了很多次 记不住五十音的你',
    img_url: 'two.png'
  },
  {
    title: '旅游日语',
    subTitle: '想去日本 自由行吃喝玩乐的你',
    img_url: 'one.png'
  },
  {
    title: '动漫日语',
    subTitle: '喜欢动漫 游戏日剧日综的你',
    img_url: 'three.png'
  },
  {
    title: '考级留学就业',
    subTitle: '去日本留学从事日语工作的你',
    img_url: 'four.png'
  },
])
</script>

<style lang="scss" scoped>
.crowd {
  width: 100%;
  // padding-top: 20px;
  height: 480px;
  // background: url(../../assets/home/crowd/srowd_bgc.png) no-repeat;
  // background: #f0f0f0;
  background-size: 100% 100%;
}

.crowd_body {
  width: 65%;
  margin-left: 17.5%;
  display: flex;
  justify-content: space-between;

  li {
    width: 22.8%;
    transition: all 0.2s linear;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
    img {
      width: 100%;
    }

    .title {
      width: 100%;
      text-align: center;
      font-size: 20px;
      margin-top: 10px;
      font-weight: bold;
      text-align: center;
    }

    .sub_title {
      width: 100%;
      margin-top: 5px;
      font-size: 15px;
      font-weight: 400;
      text-align: center;
      line-height: 26px;
      padding-bottom: 30px;
      color: #777;
    }
  }
  li:hover {
    transform: translateY(-15px);
  }
}
</style>