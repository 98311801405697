import HomeView from '../views/HomeView.vue'

export const routes = [
  {
    path: '/',
    name: 'teacher',
    component: () => import('../views/teacher-jsb.vue')
  },
  {
    path: '/index.html',
    name: 'index.html',
    component: HomeView
  },
  {
    path: '/career',  // 事业编
    name: 'career',
    component: () => import('../views/career-syb.vue')
  },
  {
    path: '/province', // 省考
    name: 'province',
    component: () => import('../views/province-sgwy.vue')
  },
  // {
  //   path: '/country', // 国考
  //   name: 'country',
  //   component: () => import('../views/country-ggwy.vue')
  // },
  // {
  //   path: '/teacher', // 教师
  //   name: 'teacher',
  //   component: () => import('../views/teacher-jsb.vue')
  // },
  // {
  //   path: '/jsb', // 教师
  //   name: 'teacher',
  //   component: () => import('../views/teacher-jsb.vue')
  // },
  {
    path: '/troops', // 军队
    name: 'troops',
    component: () => import('../views/troops-jdwz.vue')
  },
  {
    path: '/contact', // 联系我们
    name: 'contact',
    component: () => import('../views/contact-we.vue')
  },
  // home首页二级跳转
  {
    path: '/public-consultation/:id', // 企业聚集
    name: 'public-consultation',
    component: () => import('../views/public-consultation.vue')
  },
  // 在线商城
  {
    path: '/onlineShop', // 在线商城
    name: 'onlineShop',
    component: () => import('../views/online-shop')
  },
  // 详情页
  {
    path: '/shop-detail', // 详情页
    name: 'shopDetail',
    component: () => import('../views/shop-detail')
  },
  // 付款页
  {
    path: '/shop-remit', // 付款页
    name: 'shopRemit',
    component: () => import('../views/shop-remit')
  },
  // {
  //   path: '*',
  //   redirect: '/',
  //   hidden: true,
  // },
]