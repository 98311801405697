<template>
    <div class="teacher-superiority">
        <use-sub-title title="老师收入与学员上岸挂钩" :isSubTitle="false" subTitle="每位老师都是全力协助您快速上岸" class="useSubTitle"></use-sub-title>
        <div class="content">
            <div class="left">
                <p>其它机构</p>
                <ul class="leftContent">
                    <li v-for="(item, index) in otherList" :key="index" :style="tranStyle(index)">
                        {{ item }}
                    </li>
                </ul>
            </div>
            <div class="right">
                <p>汉都教育</p>
                <ul class="rightContent">
                    <li v-for="(item, index) in superList" :key="index" :style="tranStyle(index)">
                        {{ item }}
                    </li>
                </ul>
            </div>
        </div>
        <!-- <use-button>
            立即咨询
        </use-button> -->
    </div>
</template>

<script setup>
import { reactive } from "vue";

const otherList = reactive(['统一录制课件面向所有考生', '报名完成后，没人服务', '交费后不再关心你', '收人越多越好，只关注利润']);
const superList = reactive(['免费测评目前基础，精准制定提分计划', '根据您的情况，打造高效上岸方案', '所有老师工资与学员上岸挂钩', '每年限额招生，确保上岸率']);

const tranStyle = (index) => {
    return `transform: translateX(${'-' + index * 15 + 'px'})`
};
</script>

<style lang="scss" scoped>
.teacher-superiority {
    margin-top: 40px;
    width: 1903px;
    // height: 760px;
    padding-bottom: 20px;
    .content {
        width: 1203px;
        height: 402px;
        background: url('@/assets/zhuanti/teachBgc.png') no-repeat;
        margin: 0 auto;
        display: flex;

        .left {
            position: relative;
            width: 600px;

            p {
                font-weight: bold;
                font-size: 24px;
                color: #fff;
                line-height: 145px;
                position: absolute;
                right: 160px;
            }

            ul {
                position: absolute;
                right: 155px;
                top: 118px;

                li {
                    text-align: right;
                    color: #fff;
                    line-height: 72px;
                    font-size: 20px;
                }
            }
        }

        .right {
            position: relative;
            width: 600px;

            p {
                font-weight: bold;
                font-size: 30px;
                color: #fff;
                line-height: 75px;
                position: absolute;
                left: 55px;
            }

            ul {
                position: absolute;
                left: 0px;
                top: 85px;

                li {
                    text-align: left;
                    color: white;
                    line-height: 79px;
                    font-size: 24px;
                }
            }
        }
    }
}
</style>