<template>
  <div class="footer">
    <!-- <footer class="footer">
      <div class="center_body">
        <ul>
          <li>全部课程</li>
          <li>关于我们</li>
          <li>支付教程</li>
          <li>联系我们</li>
          <li>服务条款</li>
          <li>支付宝</li>
        </ul>
      </div>
      <p class="banquan">©版权所有：山东汉都教育信息服务有限公司</p>
    </footer> -->
    <div class="content">
      <div class="left">
        <ul class="detail">
          <li v-for="(item, index) in detailList" :key="index" @click="handleClick(item.url)" :class="[
            item.url.length > 0 ? 'active' : '',
            item.label.length <= 3 ? 'letter' : ''
          ]">
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="right">
        <img class="right_image" src="@/assets/handu_logo_white.png" alt="">
        <!-- <img src="@/assets/home/footerRight.png" alt=""> -->
        <br>
        <br>
        <span class="banquan" @click="openIcp">©版权所有：山东汉都教育信息服务有限公司</span>
      </div>
    </div>

  </div>
</template>

<script setup>
import { reactive } from "vue";
const detailList = reactive([
  {
    label: '全部课程',
    url: '/',
  },
  {
    label: '关于我们',
    url: '/contact',
  },
  {
    label: '支付教程',
    url: '',
  },
  {
    label: '兴趣日语',
    url: '/career',
  },
  {
    label: '联系我们',
    url: '/contact',
  },

  {
    label: '微信手机',
    url: '',
  },
  {
    label: '考级日语',
    url: '/teacher',
  },
  {
    label: '诚聘英才',
    url: '',
  },
  {
    label: '微信电脑',
    url: '',
  },
  {
    label: '考研日语',
    url: '/province',
  },
  {
    label: '服务条款',
    url: '',
  },
  {
    label: '支付宝',
    url: '',
  },
]);

const openIcp = () => {
  window.open('https://beian.miit.gov.cn/#/Integrated/index')
}

const handleClick = () => {
  // if (url != '') {
  //   window.open(url);
  // }
};
</script>

<style lang="scss" scoped>
//  .footer {
//     background-color: #333333;
//     width: 100%;
//     height: 120px;
//     ul {
//       display: flex;
//       width: 495px;
//       // margin: 0 auto;
//       padding-top: 40px;
//       margin-left: 50%;
//       transform: translateX(-50%);
//       li {
//         color: #999999;
//         margin-right: 10px;
//       }
//       li::after {
//         color: #999999;
//         content: "|";
//         width: 35px;
//         height: 24px;
//         margin-left: 10px;
//       }
//       li:last-child::after {
//         display: none;
//       }
//     }
//     .banquan {
//       text-align: center;
//       color: #999999;
//       font-size: 14px;
//       margin-top: 10px;
//     }
//   }

.footer {
  height: 260px;
  background: #333333;

  .content {
    width: 1200px;
    height: 260px;
    margin: 0 auto;
    display: flex;

    .left {
      margin-left: 60px;

      .detail {
        color: white;
        font-size: 14px;
        width: 350px;
        height: 180px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 65px;

        li {
          width: 63px;
          margin-right: 43px;
        }

        .letter {
          letter-spacing: 7px;
        }

        .active {
          cursor: pointer;
        }
      }
    }

    .right {

      height: 150px;
      border-left: 2px solid #ccc;
      margin: 70px 0 0 60px;

      .right_image {
        width: 220px;
        margin-right: -30px;
      }

      img {
        margin-left: 120px;
      }

      .banquan {
        color: #fff;
        margin-left: 120px;
        padding-top: 30px;
      }
    }
  }
}
</style>