<template>
    <div class="study-system">
        <use-sub-title title="汉都教育 AI智能学习系统" :isSubTitle="false" subTitle="报考问题人工解答清晰了解考试信息" line-width="80px"></use-sub-title>
        <ul class="body">
            <li v-for="(item, index) in bodyList" :key="index">
                <div class="topList" v-show="index < 3">
                    <p class="study-title">{{ item.title }}</p>
                    <span class="sub-span">{{ item.subTitle }}</span>
                    <img :src="require(`@/assets/zhuanti/${item.image}`)" alt="">
                    <ul class="shadow">
                        <p class="shadow-title">{{ item.title }}</p>
                        <p class="shadow-newsTitle">{{ item.newsTitle }}</p>
                        <li v-for="(ele, langs) in item.list" :key="langs">
                            <h3>{{ ele.title }}</h3>
                            <h4>{{ ele.content }}</h4>
                        </li>
                    </ul>
                </div>
                <div v-show="index > 2" class="bottomList">
                    <p class="study-title">{{ item.title }}</p>
                    <!-- <span>{{ item.subTitle }}</span> -->
                    <div class="span-cli">
                        <p v-for="(ele, indexs) in item.subTitle" :key="indexs">
                            {{ ele }}
                        </p>
                    </div>
                </div>
            </li>
        </ul>
        <!-- <use-button>立即体验</use-button> -->
    </div>
</template>

<script setup>
import { reactive } from "vue";

const bodyList = reactive([
    {
        title: '智能题库',
        subTitle: '专业职能·高效提分',
        image: 'kc1-1.png',
        newsTitle: '高效通关·提分有保障',
        list: [
            {
                title: '省时高效',
                content: '碎片时间,手机随时随地学习考试重点题目'
            },
            {
                title: '方便快捷',
                content: '手机电脑随时可以通过题库加深知识点掌握'
            },
            {
                title: '章节练习',
                content: '多种练习题型，选择做题，提分更高效'
            },
            {
                title: '模拟考试',
                content: '系统了解考试是题型，拓宽知识点运用'
            },
            {
                title: '历年真题',
                content: '通过对历年真题的练习，掌握考试重点方式'
            },
            {
                title: '专业解析',
                content: '每道题目都有详细解析，更有考点逻辑分析'
            },
        ]
    },
    {
        title: '移动课堂',
        subTitle: '一线老师授课·助力高效通关',
        image: 'kc1-2.png',
        newsTitle: '高效通关·提分有保障',
        list: [
            {
                title: '省时高效',
                content: '碎片时间,手机随时随地学习考试重点题目'
            },
            {
                title: '方便快捷',
                content: '手机电脑随时可以通过题库加深知识点掌握'
            },
            {
                title: '章节练习',
                content: '多种练习题型，选择做题，提分更高效'
            },
            {
                title: '模拟考试',
                content: '系统了解考试是题型，拓宽知识点运用'
            },
            {
                title: '历年真题',
                content: '通过对历年真题的练习，掌握考试重点方式'
            },
            {
                title: '专业解析',
                content: '每道题目都有详细解析，更有考点逻辑分析'
            },
        ]
    },
    {
        title: '网校云学堂',
        subTitle: '专业智能·高效提分',
        image: 'kc1-3.jpg',
        newsTitle: '高效通关·提分有保障',
        list: [
            {
                title: '省时高效',
                content: '碎片时间,手机随时随地学习考试重点题目'
            },
            {
                title: '方便快捷',
                content: '手机电脑随时可以通过题库加深知识点掌握'
            },
            {
                title: '章节练习',
                content: '多种练习题型，选择做题，提分更高效'
            },
            {
                title: '模拟考试',
                content: '系统了解考试是题型，拓宽知识点运用'
            },
            {
                title: '历年真题',
                content: '通过对历年真题的练习，掌握考试重点方式'
            },
            {
                title: '专业解析',
                content: '每道题目都有详细解析，更有考点逻辑分析'
            },
        ]
    },
    {
        title: 'AI学习系统',
        subTitle: ['台式电脑及智能电视', '便携式电脑', '平板电脑', '智能手机'],
        image: 'kc1-4.jpg'
    },
]);
</script>

<style lang="scss" scoped>
.study-system {
    // min-height: 810px;
    padding-bottom: 20px;
    width: 1200px;
    margin: 0 auto;

    .body {
        display: flex;
        height: 550px;
        border: 1px solid #e4e4e4;
        li {
            width: 300px;
            height: 550px;
            text-align: center;
            overflow: hidden;
            position: relative;

            .shadow {
                // width: 300px;
                // height: 550px;
                width: 300px;
                height: 550px;
                transition: all 0.3s;
                position: absolute;
                top: 0px;
                background: rgba(62, 131, 255, 0.9);
                z-index: 2;
                opacity: 0;

                .shadow-title {
                    display: block;
                    width: 243px;
                    margin-top: 26px;
                    text-align: left;
                    font-size: 24px;
                    color: #fff;
                    font-weight: normal;
                    margin-left: 50%;
                    transform: translateX(-50%);
                }

                li {
                    width: 243px;
                    height: 53px;
                    background: url('@/assets/zhuanti/bg_12.png') no-repeat center center;
                    background-size: 243px 53px;
                    margin: 15px 0;
                    color: #fff;
                    margin-left: 30px;

                    h3 {
                        text-align: left;
                        font-size: 16px;
                        color: #0096ff;
                        font-weight: normal;
                        margin-left: 8px;
                        padding-top: 3px;
                    }

                    h4 {
                        font-size: 12px;
                        color: #fff;
                        font-weight: normal;
                        text-align: left;
                        margin-top: 7px;
                        margin-left: 9px;
                    }
                }

                .shadow-newsTitle {
                    width: 243px;
                    text-align: left;
                    font-size: 16px;
                    color: #fff;
                    height: 36px;
                    line-height: 36px;
                    border-bottom: solid 1px #fff;
                    margin-left: 50%;
                    transform: translateX(-50%);
                }
            }

            .study-title {
                font-size: 34px;
                color: #333;
                font-weight: normal;
                margin-top: 20px;

            }

            .sub-span {
                display: inline-block;
                font-size: 18px;
                color: #fff;
                background: #0096ff;
                height: 36px;
                line-height: 36px;
                padding: 0 12px;
                border-radius: 50px;
                margin-top: 10px;
            }

            img {
                display: inline-block;
                margin-top: 40px;
                width: 85%;
            }

            .bottomList {
                .span-cli {
                    width: 270px;
                    background-color: #0096ff;
                    margin: 8px auto 0 auto;
                    border-radius: 10px;
                    padding: 9px 0;
                    color: #fff;

                    p {
                        font-size: 18px;
                        color: #fff;
                        padding-left: 15px;
                        text-align: left;
                        background: url('@/assets/zhuanti/d.jpg') no-repeat left center;
                        margin: 6px 0px 6px 45px;
                    }
                }
            }
        }

        li:hover {
            .shadow {
                opacity: 1;
                width: 300px;
                height: 550px;

            }
        }

        li:nth-child(2) {
            background: #ededed;
        }

        li:nth-child(4) {
            background: url('@/assets/zhuanti/kc1-4.jpg') no-repeat;
        }
    }
}
</style>