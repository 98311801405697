import axios from "axios";
import {
    ElMessageBox
} from 'element-plus';
// process.env.VUE_APP_URL
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '/api' : 'https://admin.jpnconfig.top/public/index.php/admin/'
console.log(process.env.NODE_ENV, 'axios.defaults.baseURL')
//post请求头
// axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
//设置超时
axios.defaults.timeout = 10000;

axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {

        ElMessageBox(JSON.stringify(error), '请求异常', {
            confirmButtonText: '确定',
            callback: action => {
                console.log(action, 'action')
            }
        });
    }
);
export function apiGet(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}
export function apiPost(url, params) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: url,
            data: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}